import type React from 'react'

import { type TError, type TSetState, type TVoid } from '../../../interfaces'

export const createHandlePrepend =
	(prepend: React.MutableRefObject<HTMLElement | null>, input: React.MutableRefObject<HTMLInputElement | null>): TVoid =>
	() => {
		const handlePrepend = (): void => {
			if (!prepend.current || !input.current) return

			input.current.style.paddingLeft = `${prepend.current.offsetWidth}px`
		}

		handlePrepend()

		const prependInterval = setInterval(handlePrepend, 1000)

		return () => {
			clearInterval(prependInterval)
		}
	}

export const createHandleLocalError =
	(
		input: React.MutableRefObject<HTMLInputElement | HTMLSelectElement | null>,
		setError: TSetState,
		localShowError: boolean,
		setLocalShowError: TSetState,
		handleError?: TError
	): TVoid =>
	(): void => {
		if (!input.current) return

		const isError = !input.current.checkValidity()

		setError(isError)

		if (handleError) {
			handleError(isError)
		}

		if (!isError && localShowError) {
			setLocalShowError(false)
		}
	}
