import React from 'react'
import { type ISimpleComponent } from '../../interfaces'

export interface ISteps extends ISimpleComponent {
	active: string
}

export const Steps = ({ children, active }: ISteps): React.JSX.Element => {
	if (Array.isArray(children)) {
		return children.find((c) => c.props.name === active) ?? <></>
	}

	return children
}
