import React, { useEffect, useRef, useState } from 'react'
import { createHandleChange } from '../../utils/common'
import type { IFormControl, TVoid, TError } from '../../interfaces'
import classNames from 'classnames'
import { createHandleLocalError, createHandlePrepend } from './utils/common'

export interface IInput extends IFormControl<string | number> {
	type?: 'text' | 'email' | 'tel'
	prependText?: string
	placeholder?: string
	handleError?: TError
	handleBlur?: TVoid
	errorMessage?: string
	showError?: boolean
}

export const Input = ({
	label,
	type,
	value,
	prependText,
	placeholder,
	handleChange,
	handleError,
	handleBlur,
	required,
	errorMessage,
	showError
}: IInput): React.JSX.Element => {
	const prepend: React.MutableRefObject<HTMLElement | null> = useRef(null)
	const input: React.MutableRefObject<HTMLInputElement | null> = useRef(null)

	const [error, setError] = useState(false)
	const [localShowError, setLocalShowError] = useState(showError === true)

	const handleLocalError = createHandleLocalError(input, setError, localShowError, setLocalShowError, handleError)
	const handleLocalChange = (value: string): void => {
		handleLocalError()
		handleChange(value)
	}

	useEffect(createHandlePrepend(prepend, input))
	useEffect(handleLocalError, [value])

	useEffect(() => {
		if (!showError) return

		setLocalShowError(showError)
	}, [showError])

	return (
		<div
			className={classNames('form-field', {
				'with-error': error && localShowError
			})}>
			<div className={classNames('input', { 'with-prepend': prependText })}>
				{prependText && (
					<span className="prepend" ref={prepend}>
						{prependText}
					</span>
				)}
				<input
					type={type ?? 'text'}
					placeholder={placeholder ?? label ?? ''}
					onChange={createHandleChange(handleLocalChange)}
					onBlur={
						handleBlur
							? () => {
									handleBlur()
							  }
							: undefined
					}
					value={value}
					ref={input}
					required={required}
				/>
			</div>
			{errorMessage && <div className="error">{errorMessage}</div>}
		</div>
	)
}
