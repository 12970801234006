import React from 'react'
import type { IFormStepForm } from '.'
import { type IPaymentStepForm, PaymentForm } from '../components'
import { Stripe } from '../../../components/wrapper'

interface IPaymentStep {
	amount: number
	form: IFormStepForm | null
	handleAction: (form: IPaymentStepForm) => void
	showError: boolean
	validated: boolean
}

export const PaymentStep = ({ amount, form, handleAction }: IPaymentStep): React.JSX.Element =>
	form ? (
		<Stripe>
			<PaymentForm amount={amount} form={form} handleAction={handleAction} />
		</Stripe>
	) : (
		<></>
	)
