import { camelCase, kebabCase } from 'lodash'
import { type TRecord } from '../interfaces'
import { getRequestParameters } from './common'

export const style: TRecord<string> = {
	backgroundColor: '#fff',
	lightTextColor: '#fff',
	darkTextColor: '#000',
	baseColor: '#2980b9',
	secondaryColor: '#ecf0f1',
	lighterColor: '#3498db',
	darkerColor: '#8e44ad',
	errorColor: '#d63031'
}

const screenLgMin = 1240
const screenMdMin = 768
const screenSmMin = 480
const screenXsMin = 280

const breakpoints: TRecord<number> = {
	screenLgMin,
	screenMdMin,
	screenMdMax: screenLgMin,
	screenSmMin,
	screenSmMax: screenMdMin,
	screenXsMin,
	screenXsMax: screenSmMin
}

export const isMobile = (): boolean => window.innerWidth < breakpoints.screenSmMax

export const setStyle = (): void => {
	const properties = getRequestParameters()

	Object.entries(properties).forEach(([key, value]) => {
		const parsedKey = `${camelCase(key)}Color`

		if (typeof value !== 'string' || !value || !style[parsedKey]) return

		style[parsedKey] = value
	})
}

export const getStyle = (): TRecord<string> => {
	const css: TRecord<string> = {}

	Object.entries(style).forEach(([key, value]) => {
		css[`--${kebabCase(key)}`] = value
	})

	return css
}
