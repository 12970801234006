import { type TRecord } from '../interfaces'
import { debounceTime } from './defaults'

interface IWidgetDefinition {
	active: boolean
	uuid: string | null
	interval: NodeJS.Timer | null
}

const widget: IWidgetDefinition = {
	active: false,
	uuid: null,
	interval: null
}

let prevSize: number | null = null

export const isWidget = (): boolean => widget.active

export const sendMessage = (type: string, data: TRecord = {}): void => {
	window.parent.postMessage({ ...data, type: `dp-widget-${type}`, uuid: widget.uuid }, '*')
}

const syncHeight = (): void => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const nextSize = document.querySelector<HTMLElement>('#root')!.offsetHeight

	if (!prevSize || prevSize !== nextSize) {
		sendMessage('resize', { height: nextSize })

		prevSize = nextSize
	}
}

const watchHeight = (): void => {
	syncHeight()

	widget.interval = setInterval(() => {
		syncHeight()
	}, debounceTime)
}

export const setupWidget = (uuid: string): void => {
	if (widget.interval) {
		clearInterval(widget.interval)
		widget.interval = null
	}

	widget.active = true
	widget.uuid = uuid

	watchHeight()
}
