import { createContext } from 'react'
import { type IDonateAction, type IDonateReducer } from '../reducers'
import { type IContext } from '../interfaces'

const initialContext: IContext<IDonateReducer, IDonateAction> = {
	state: null,
	dispatch: null
}

export const DonateContext = createContext(initialContext)
