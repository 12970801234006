import { Donate } from '../utils/actionTypes'
import { type IReducer, type TRecord } from '../interfaces'

export interface IConnectAccount {
	_id: string
	email: string
	stripe_account_id: string
	onBoarded?: boolean
	ready_for_payment?: boolean
	synced_with_contracts?: boolean
	is_default?: boolean
	display_name?: string
}

export interface IDonateWidget {
	_id: string
	name: string
	description: string
	preset_amounts: number[]
	thank_you_page_url: string
	ga_measurement_id?: string
	goal_amount?: number
	total_donation_amount?: number
	minimum_donation_amount?: number
	connect_accounts?: IConnectAccount[]
	enable_multi_connect_account?: boolean
	ext_plan_subscription_id?: string
}

export interface IDonateFee {
	donation_amount: number
	stripe_base_fee: number
	stripe_currency_conversion_fee: number
	stripe_international_fee: number
	stripe_total_fee: number
	total_amount: number
}

export interface IDonateDefinition {
	donation: TRecord<any>
	payment_intent: TRecord<any>
}

export interface IDonateReducer {
	definition: null | IDonateDefinition
	donation: null | IDonateWidget
	widget: null | IDonateWidget
	fee: null | IDonateFee
	confirmed: boolean
	error: boolean
	loading: boolean
}

export interface IDonateAction {
	type: string
	donation?: IDonateWidget
	fee?: IDonateFee
	definition?: IDonateDefinition
	uuid?: string
}

export const donate: IReducer<IDonateReducer> = {
	initialState: {
		confirmed: false,
		definition: null,
		donation: null,
		widget: null,
		fee: null,
		error: false,
		loading: false
	},
	reducer: (state: IDonateReducer, action: IDonateAction) => {
		switch (action.type) {
			case Donate.FETCH.REQUESTED:
				return {
					...state,
					donation: null,
					loading: true,
					error: false
				}
			case Donate.FETCH.SUCCESS:
				return {
					...state,
					donation: action.donation,
					loading: false,
					error: false
				}
			case Donate.FETCH.ERROR:
				return { ...state, loading: false, error: true }
			case Donate.CALCULATE_FEE.REQUESTED:
				return { ...state, loading: true, error: false }
			case Donate.CALCULATE_FEE.SUCCESS:
				return {
					...state,
					fee: action.fee,
					loading: false,
					error: false
				}
			case Donate.CALCULATE_FEE.ERROR:
				return { ...state, loading: false, error: true }
			case Donate.INIT.REQUESTED:
				return {
					...state,
					definition: null,
					loading: true,
					error: false
				}
			case Donate.INIT.SUCCESS:
				return {
					...state,
					definition: action.definition,
					loading: false,
					error: false
				}
			case Donate.INIT.ERROR:
				return { ...state, loading: false, error: true }
			case Donate.CONFIRM.REQUESTED:
				return {
					...state,
					confirmed: false,
					loading: true,
					error: false
				}
			case Donate.CONFIRM.SUCCESS:
				return {
					...state,
					confirmed: true,
					loading: false,
					error: false
				}
			case Donate.CONFIRM.ERROR:
				return { ...state, loading: false, error: true }
			default:
				return state
		}
	}
}
