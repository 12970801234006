/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { FaLock } from 'react-icons/fa'
import { toNumber } from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { Button, Form, Input, InputNumber, InputPhone, Select } from '../../../components/form'
import { Checkbox } from '../../../components/form/checkbox'
import { Notification, Tag } from '../../../components/element'
import { DonateContext } from '../../../contexts'
import { formatPrice, getRequestParameters } from '../../../utils/common'
import { type TErrorCreator, type TRecord } from '../../../interfaces'

import {
	defaultFormStepAmountTitle,
	defaultFormStepAmountSubTitle,
	defaultFormStepCustomAmountLabel,
	defaultFormStepUserFormTitle,
	defaultFormStepUserFormSubtitle,
	defaultFormStepFirstNameLabel,
	defaultFormStepLastNameLabel,
	defaultFormStepEmailLabel,
	defaultFormStepPhoneNumberLabel,
	defaultFormStepTransactionFeeConsentLabel,
	defaultFormStepRecurringConsentLabel,
	defaultFormStepContinueButtonText,
	defaultFormStepSecurityGuaranteeText,
	defaultFormStepErrorNotificationMessage,
	defaultFormStepTargetTitle,
	defaultFormStepTargetSubTitle
} from '../../../utils/defaults'

export interface IFormStepForm {
	firstName: string
	lastName: string
	email: string
	fee: boolean
	recurring: boolean
	phone: string
	targetStripeConnectAccount: string
}

interface IFormStep {
	amount: number
	handleAmountSet: (amount: number) => void
	handleAction: (form: IFormStepForm) => void
	handleError: TErrorCreator
	showError: boolean
	validated: boolean
}

const {
	target_title: targetTitle,
	target_subtitle: targetSubtitle,
	amount_title: amountTitle,
	amount_subtitle: amountSubtitle,
	custom_amount_label: customAmountLabel,
	user_form_title: userFormTitle,
	user_form_subtitle: userFormSubtitle,
	first_name_label: firstNameLabel,
	last_name_label: lastNameLabel,
	email_label: emailLabel,
	phone_number_label: phoneNumberLabel,
	transaction_fee_consent_label: transactionFeeConsentLabel,
	recurring_consent_label: recurringConsentLabel,
	continue_button_text: continueButtonText,
	security_guarantee_text: securityGuaranteeText,
	error_notification_message: errorNotificationMessage
} = getRequestParameters() as TRecord<string>

export const FormStep = ({ amount, handleAmountSet, handleAction, handleError, showError, validated }: IFormStep): React.JSX.Element => {
	const { state } = useContext(DonateContext)

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [fee, setFee] = useState(false)
	const [connectAccountsSelectInited, setConnectAccountsSelectInited] = useState(false)
	const [recurring, setRecurring] = useState(false)
	const [targetStripeConnectAccount, setTargetStripeConnectAccount] = useState<string>('')

	const input: React.MutableRefObject<HTMLElement | null> = useRef(null)

	const handleAmountClick = (newAmount: string): void => {
		if (!state?.donation) return

		if (newAmount || (newAmount === '' && state.donation.preset_amounts.includes(amount))) {
			handleAmountSet(toNumber(newAmount))
		}

		if (!newAmount && input.current) {
			input.current.focus()
		}
	}

	const handleInputBlur = (): void => {
		if (!amount) {
			handleAmountSet(0)
		}
	}

	const handleSelectAccount = (connectedAccountId: string): void => {
		setTargetStripeConnectAccount(connectedAccountId)
	}

	const handleSubmit = (): void => {
		handleAction({
			firstName,
			lastName,
			email,
			fee,
			recurring,
			phone,
			targetStripeConnectAccount
		})
	}

	useEffect(() => {
		if (!connectAccountsSelectInited && (state?.donation?.connect_accounts?.length ?? 0) > 1 && state?.donation?.enable_multi_connect_account) {
			const defaultAccount = state?.donation?.connect_accounts?.find(({ is_default: isDefault }) => isDefault) ?? null
			setTargetStripeConnectAccount(defaultAccount?.stripe_account_id ?? '')
			setConnectAccountsSelectInited(true)
		}
	}, [state, connectAccountsSelectInited])

	if (!state?.donation) return <></>

	const minimumDonationAmount = state?.donation?.minimum_donation_amount ?? 5

	const connectAccountOptions =
		state.donation?.connect_accounts?.map(
			({ stripe_account_id: connectAccountId, email, display_name: displayName, stripe_account_id: stripeAccountId }) => ({
				value: connectAccountId,
				label: displayName ?? `${email} ${stripeAccountId}`
			})
		) ?? []

	return (
		<Form>
			<div className="donate-box">
				<div className="donate-header">
					{(state.error || !validated) && (
						<>
							{state.error ? (
								<Notification type="error" title={errorNotificationMessage ?? defaultFormStepErrorNotificationMessage} />
							) : (
								<Notification type="error" title="Please fix errors in the form." />
							)}
						</>
					)}
				</div>
				{state.donation.enable_multi_connect_account && state.donation.connect_accounts && state.donation.connect_accounts.length > 1 && (
					<>
						<div className="donate-header">
							<h2>{targetTitle ?? defaultFormStepTargetTitle}</h2>
							<h4>{targetSubtitle ?? defaultFormStepTargetSubTitle}</h4>
						</div>
						<div className="donate-content">
							<Select value={targetStripeConnectAccount} handleChange={handleSelectAccount} options={connectAccountOptions} />
						</div>
					</>
				)}
				<div className="donate-header">
					<h2>{amountTitle ?? defaultFormStepAmountTitle}</h2>
					<h4>{amountSubtitle ?? defaultFormStepAmountSubTitle}</h4>
				</div>
				<div className="donate-content">
					<InputNumber
						ref={input}
						value={amount}
						handleChange={handleAmountSet}
						handleError={handleError('amount')}
						handleBlur={handleInputBlur}
						prependText="$"
						min={minimumDonationAmount}
						max={1000000}
						size="large"
						text="right"
						errorMessage={`Donation amount should be in the range of 
						${formatPrice(minimumDonationAmount)} - ${formatPrice(1000000)}.`}
						showError={showError}
						required
					/>
					<div className="buttons">
						{state.donation.preset_amounts.map((preset: number) => (
							<Button
								key={preset}
								active={amount === preset}
								text={formatPrice(preset)}
								value={preset.toString()}
								action={handleAmountClick}
							/>
						))}
						<Button
							active={!state.donation.preset_amounts.includes(amount)}
							text={customAmountLabel ?? defaultFormStepCustomAmountLabel}
							value=""
							action={handleAmountClick}
							small
						/>
					</div>
				</div>
			</div>
			<div className="donate-box">
				<div className="donate-header">
					<h2>{userFormTitle ?? defaultFormStepUserFormTitle}</h2>
					<h4>{userFormSubtitle ?? defaultFormStepUserFormSubtitle}</h4>
				</div>
				<div className="donate-content">
					<div className="row row-wide">
						<div className="col-sm-2-4 col-md-3-6 col-lg-6-12 form-field-xs">
							<Input
								placeholder={firstNameLabel ?? defaultFormStepFirstNameLabel}
								value={firstName}
								handleChange={setFirstName}
								handleError={handleError(firstNameLabel ?? defaultFormStepFirstNameLabel)}
								required
								errorMessage={`${firstNameLabel ?? defaultFormStepFirstNameLabel} is empty`}
								showError={showError}
							/>
						</div>
						<div className="col-sm-2-4 col-md-3-6 col-lg-6-12 form-field-xs">
							<Input
								placeholder={lastNameLabel ?? defaultFormStepLastNameLabel}
								value={lastName}
								handleChange={setLastName}
								handleError={handleError(lastNameLabel ?? defaultFormStepLastNameLabel)}
								required
								errorMessage={`${lastNameLabel ?? defaultFormStepLastNameLabel} is empty`}
								showError={showError}
							/>
						</div>
					</div>
					<Input
						placeholder={emailLabel ?? defaultFormStepEmailLabel}
						type="email"
						value={email}
						handleChange={setEmail}
						handleError={handleError(emailLabel ?? defaultFormStepEmailLabel)}
						required
						errorMessage={`${emailLabel ?? defaultFormStepEmailLabel} is empty or invalid`}
						showError={showError}
					/>
					<InputPhone
						placeholder={phoneNumberLabel ?? defaultFormStepPhoneNumberLabel}
						value={phone}
						handleChange={setPhone}
						handleError={handleError(phoneNumberLabel ?? defaultFormStepPhoneNumberLabel)}
						required
						errorMessage={`${phoneNumberLabel ?? defaultFormStepPhoneNumberLabel} is empty or not a valid international format`}
						showError={showError}
					/>
				</div>
			</div>
			<div className="donate-box">
				<div className="donate-content">
					{state.fee && (
						<Checkbox
							label={
								transactionFeeConsentLabel
									? transactionFeeConsentLabel.replaceAll('{{STRIPE_FEE}}', formatPrice(state.fee.stripe_total_fee))
									: defaultFormStepTransactionFeeConsentLabel(state.fee.stripe_total_fee)
							}
							value={fee}
							handleChange={setFee}
						/>
					)}
					<Checkbox label={recurringConsentLabel ?? defaultFormStepRecurringConsentLabel} value={recurring} handleChange={setRecurring} />
				</div>
				<div className="donate-content">
					<Button text={continueButtonText ?? defaultFormStepContinueButtonText} action={handleSubmit} loading={state.loading} />
					<div className="text-center">
						<Tag icon={<FaLock color="gold" />} text={securityGuaranteeText ?? defaultFormStepSecurityGuaranteeText} />
					</div>
				</div>
			</div>
		</Form>
	)
}
