import ReactGA from 'react-ga4'
import type { UaEventOptions } from 'react-ga4/types/ga4'
import { appName } from './defaults'
import { getRequestParameters } from './common'

export interface IGA4EventData extends UaEventOptions {
	item_id: string
	item_name: string
	item_brand: string
	affiliation: string
	item_category: string
	item_category_2: string
	item_category_3: string
	item_target_account: string
}

export interface IGA4 {
	sendEvent: (name: string, data: IGA4EventData) => void
	sendView: (data: object) => void
}

export const getGA4 = (): IGA4 | null => {
	const { gaId: id } = getRequestParameters()

	if (!id || typeof id !== 'string') return null

	ReactGA.initialize(id, {
		gaOptions: {
			name: `${appName} Tracker`
		}
	})

	return {
		sendEvent: (name: string, data: UaEventOptions): void => {
			ReactGA.event(name, { ...data })
		},
		sendView: (data: any): void => {
			ReactGA.send({ ...data })
		}
	}
}
