import React from 'react'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { Helmet } from 'react-helmet'

import { Button } from '../../../components/form'
import { getRequestParameters } from '../../../utils/common'
import type { TRecord } from '../../../interfaces'

import { defaultFormStepTargetTitle, defaultFormStepTargetSubTitle } from '../../../utils/defaults'

const { target_title: targetTitle, target_subtitle: targetSubtitle, parent_page_url: parentPageUrl } = getRequestParameters() as TRecord<string>

export const ThankYouStep = (): React.JSX.Element => (
	<>
		<Helmet>
			<meta property="og:locale" content="en_US" />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={targetTitle ?? defaultFormStepTargetTitle} />
			<meta property="og:quote" content={targetSubtitle ?? defaultFormStepTargetSubTitle} />
			<meta property="og:hashtag" content="#donate" />
			<meta property="og:url" content={window.location.href} />
			<meta property="og:site_name" content="CampersTribe" />
			<meta property="og:description" content={targetSubtitle ?? defaultFormStepTargetSubTitle} />
		</Helmet>
		<div className="donate-box">
			<div className="donate-header">
				<h2>Thank you so much for your generous donation!</h2>
			</div>
			<div className="donate-content">
				<p className="big">
					Thank you so much for your generous donation! Your contribution is not just a gift, but an investment in making our world a better
					place. We&apos;re incredibly grateful and promise to put every cent to work for the cause. We&apos;ll keep you updated on how your
					contribution is making a difference. Thanks again for your amazing support!
				</p>
				<p className="big">
					Could you lend a hand in amplifying our message? Share your commitment to our cause with your network of friends and followers!
				</p>
			</div>
		</div>
		<div className="donate-box">
			<div className="donate-content">
				<div className="row row-wide">
					<div className="col-sm-2-4 col-md-3-6 col-lg-6-12">
						<FacebookShareButton type="button" url={parentPageUrl ?? window.location.href} hashtag="#donate">
							<Button text="Facebook" action={() => {}} small />
						</FacebookShareButton>
					</div>
					<div className="col-sm-2-4 col-md-3-6 col-lg-6-12 space-xs">
						<TwitterShareButton type="button" url={parentPageUrl ?? window.location.href} hashtags={['#donate']}>
							<Button text="Twitter" action={() => {}} small />
						</TwitterShareButton>
					</div>
				</div>
			</div>
		</div>
	</>
)
