import React from 'react'

export type TEntry = [string, string]

export interface ISummary {
	entries: TEntry[]
	summary: TEntry
}

const printEntry = ([name, value]: TEntry): React.JSX.Element => (
	<tr key={name}>
		<td>{name}</td>
		<td>{value}</td>
	</tr>
)

export const Summary = ({ entries, summary }: ISummary): React.JSX.Element => (
	<table className="summary-element">
		<tbody>{entries.map((entry) => printEntry(entry))}</tbody>
		<tfoot>{printEntry(summary)}</tfoot>
	</table>
)
