export const Donate = {
	FETCH: {
		REQUESTED: 'DONATE_FETCH_REQUESTED',
		SUCCESS: 'DONATE_FETCH_SUCCESS',
		ERROR: 'DONATE_FETCH_ERROR'
	},
	INIT: {
		REQUESTED: 'DONATE_INIT_REQUESTED',
		SUCCESS: 'DONATE_INIT_SUCCESS',
		ERROR: 'DONATE_INIT_ERROR'
	},
	CONFIRM: {
		REQUESTED: 'DONATE_CONFIRM_REQUESTED',
		SUCCESS: 'DONATE_CONFIRM_SUCCESS',
		ERROR: 'DONATE_CONFIRM_ERROR'
	},
	CALCULATE_FEE: {
		REQUESTED: 'DONATE_CALCULATE_FEE_REQUESTED',
		SUCCESS: 'DONATE_CALCULATE_FEE_SUCCESS',
		ERROR: 'DONATE_CALCULATE_FEE_ERROR'
	}
}
