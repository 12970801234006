import './styles/main.sass'
import React, { useReducer } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Error404 } from './scenes/common'
import { DonateWidget } from './scenes/donate'
import { DonateContext } from './contexts'
import { donate } from './reducers'

const App = (): React.JSX.Element => {
	const [donateState, donateDispatch] = useReducer(donate.reducer, donate.initialState)

	return (
		<Routes>
			<Route path="/404" element={<Error404 />} />
			<Route
				path="/donate/:id/:uuid"
				element={
					<DonateContext.Provider value={{ state: donateState, dispatch: donateDispatch }}>
						<DonateWidget />
					</DonateContext.Provider>
				}
			/>

			<Route path="*" element={<Navigate to="/404" replace />} />
		</Routes>
	)
}

export default App
