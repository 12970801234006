import React, { useEffect, useRef, useState } from 'react'
import { createHandleChange } from '../../utils/common'
import type { IFormControl, TVoid, TError } from '../../interfaces'
import classNames from 'classnames'
import { createHandleLocalError } from './utils/common'

export interface ISelect extends IFormControl<string | number> {
	options: Array<{ label: string; value: string }>
	handleError?: TError
	handleBlur?: TVoid
	errorMessage?: string
	showError?: boolean
}

export const Select = ({ value, options, handleChange, handleError, handleBlur, required, errorMessage, showError }: ISelect): React.JSX.Element => {
	const select: React.MutableRefObject<HTMLSelectElement | null> = useRef(null)

	const [error, setError] = useState(false)
	const [localShowError, setLocalShowError] = useState(showError === true)

	const handleLocalError = createHandleLocalError(select, setError, localShowError, setLocalShowError, handleError)
	const handleLocalChange = (value: string): void => {
		handleLocalError()
		handleChange(value)
	}

	useEffect(handleLocalError, [value])

	useEffect(() => {
		if (!showError) return

		setLocalShowError(showError)
	}, [showError])

	return (
		<div
			className={classNames('form-field', {
				'with-error': error && localShowError
			})}>
			<div className="select">
				<select
					ref={select}
					onChange={createHandleChange(handleLocalChange)}
					onBlur={
						handleBlur
							? () => {
									handleBlur()
							  }
							: undefined
					}
					required={required}>
					{options.map((option) => (
						<option key={option.value} value={option.value} selected={option.value === value}>
							{option.label}
						</option>
					))}
				</select>
			</div>
			{errorMessage && <div className="error">{errorMessage}</div>}
		</div>
	)
}
