import React from 'react'

export interface ITag {
	icon: React.JSX.Element
	text: string
}

export const Tag = ({ icon, text }: ITag): React.JSX.Element => (
	<div className="tag-element">
		{icon}
		<span>{text}</span>
	</div>
)
