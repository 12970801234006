import React from 'react'
import { Select } from '.'
import { type IFormControl, type TError, type TVoid } from '../../interfaces'
import { countries } from 'countries-list'

export interface ICountrySelect extends IFormControl<string | number> {
	handleError?: TError
	handleBlur?: TVoid
	errorMessage?: string
	showError?: boolean
}

export const CountrySelect = ({
	value,
	handleChange,
	handleError,
	handleBlur,
	required,
	errorMessage,
	showError
}: ICountrySelect): React.JSX.Element => (
	<Select
		options={[
			{
				label: 'Select country',
				value: ''
			},
			...Object.entries(countries).map(([value, { name: label }]) => ({
				label,
				value
			}))
		]}
		value={value}
		handleChange={handleChange}
		handleBlur={handleBlur}
		handleError={handleError}
		required={required}
		errorMessage={errorMessage}
		showError={showError}
	/>
)
