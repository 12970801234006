import React, { useState, useEffect } from 'react'

import { sanitizeAmount } from '../../utils/common'

export interface IProgressBar {
	goal: number
	current: number
}

export const ProgressBar = ({ goal, current }: IProgressBar): React.JSX.Element => {
	const [percentage, setPercentage] = useState(0)

	useEffect(() => {
		const newPercentage = (current / goal) * 100
		setPercentage(newPercentage)
	}, [current, goal])

	return (
		<div className="progress-bar-wrapper">
			<div className="current">
				<h3>${sanitizeAmount(current)} raised</h3>
			</div>
			<div className="goal">
				<h4>out of ${sanitizeAmount(goal)}</h4>
			</div>
			<div className="progress-bar">
				<div className="progress-bar-fill" style={{ width: `${percentage}%` }} />
			</div>
		</div>
	)
}
