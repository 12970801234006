import React, { useContext, useState } from 'react'
import { type ISimpleComponent } from '../../interfaces'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js'
import { debounceTime, stripePublishableKey } from '../../utils/defaults'
import { DonateContext } from '../../contexts'
import { Form } from '../form'
import { isMobile, style } from '../../utils/styles'

let mobileSwitchTimeout: number

export const Stripe = ({ children }: ISimpleComponent): React.JSX.Element => {
	const { state } = useContext(DonateContext)

	const [mobile, setMobile] = useState(isMobile())

	if (!state?.definition) return <Form>{children}</Form>

	window.addEventListener(
		'resize',
		() => {
			window.clearTimeout(mobileSwitchTimeout)

			mobileSwitchTimeout = window.setTimeout(() => {
				if (isMobile() && !mobile) {
					setMobile(true)
				}

				if (!isMobile() && mobile) {
					setMobile(false)
				}
			}, debounceTime)
		},
		false
	)

	const stripePromise = loadStripe(stripePublishableKey, {
		stripeAccount: state.definition.payment_intent.stripe_account_id
	})

	const options: StripeElementsOptions = {
		fonts: [
			{
				cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
			}
		],
		clientSecret: state.definition.payment_intent.client_secret,
		loader: 'always',
		locale: 'en',
		appearance: {
			disableAnimations: true,
			theme: 'none',
			variables: {
				fontFamily: 'Roboto, sans-serif',
				fontSizeBase: '16px'
			},
			rules: {
				'.Label': {
					fontSize: '0',
					marginTop: '0'
				},
				'.Input': {
					fontSize: mobile ? '1rem' : '1.25rem',
					padding: mobile ? '0.75rem 1rem' : '1rem 1.25rem',
					border: `1px solid ${style.baseColor}`,
					boxShadow: `inset 0 0 3px ${style.baseColor}`,
					outline: 'none',
					transition: 'all 0.25s'
				},
				'.Input:hover, .Input:focus': {
					border: `1px solid ${style.darkerColor}`,
					boxShadow: `inset 0 0 5px ${style.darkerColor}`,
					outline: 'none'
				},
				'.Error': {
					marginTop: mobile ? '0.5rem' : '1rem',
					fontSize: mobile ? '0.75rem' : '1rem',
					fontWeight: '700',
					color: style.errorColor
				}
			}
		}
	}

	return (
		<Elements stripe={stripePromise} options={options}>
			{children}
		</Elements>
	)
}
