import type React from 'react'
import { type TRecord, type TSetState, type TValueParser } from '../interfaces'
import { isWidget, sendMessage } from './widget'
import qs from 'qs'

export const createHandleChange =
	(callback: TSetState, parseValue?: TValueParser) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (parseValue) {
			callback(parseValue(e.target.value))
		} else {
			callback(e.target.value)
		}
	}

const setError =
	(uuid: string, isError: boolean) =>
	(error: TRecord): TRecord => ({
		...error,
		[uuid]: isError
	})

export const createHandleError =
	(callback: TSetState) =>
	(uuid: string) =>
	(isError: boolean): void => {
		callback(setError(uuid, isError))
	}

export const createHandleShowError = (callback: TSetState) => (): void => {
	// eslint-disable-next-line n/no-callback-literal
	callback(true)

	setTimeout(() => {
		// eslint-disable-next-line n/no-callback-literal
		callback(false)
	}, 0)
}

export const createValidate = (error: TRecord) => (): boolean => !Object.values(error).some((e) => e)

export const formatPrice = (price: number): string =>
	price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD'
	})

export const getFullName = (firstName: string, lastName: string): string => {
	if (firstName && lastName) {
		return `${firstName} ${lastName}`
	}

	return firstName || lastName
}

export const redirect = (url: string): void => {
	window.parent.location.href = url
}

export const scrollTop = (): void => {
	if (isWidget()) {
		sendMessage('scroll')
	} else {
		window.scrollTo(0, 0)
	}
}

export const getRequestParameters = (): TRecord => qs.parse(window.location.search.slice(1))

export const getUtmVariablesFromUrl = (): object => {
	const requestParams = getRequestParameters()

	const { utm_campaign: utmCampaign, utm_content: utmContent, utm_medium: utmMedium, utm_term: utmTerm, utm_source: utmSource } = requestParams

	return {
		utm_campaign: utmCampaign,
		utm_content: utmContent,
		utm_medium: utmMedium,
		utm_term: utmTerm,
		utm_source: utmSource
	}
}

export const sanitizeAmount = (value: string | number, precision: string | number = 2): number => {
	if (precision === 'auto' && value) {
		const fraction = parseFloat(parseFloat(value as string).toFixed(2)) - parseInt(value as string)
		return fraction ? parseFloat(parseFloat(value as string).toFixed(2)) : parseInt(value as string)
	}

	return parseFloat(parseFloat(value as string).toFixed(precision as number))
}
