import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createHandleChange } from '../../utils/common'
import type { IFormControl, TError, TVoid } from '../../interfaces'
import { toNumber } from 'lodash'
import classNames from 'classnames'
import { createHandleLocalError, createHandlePrepend } from './utils/common'

export interface IInputNumber extends IFormControl<number> {
	prependText?: string
	placeholder?: string
	step?: number | 'any'
	min?: number
	max?: number
	text?: 'left' | 'right'
	size?: 'normal' | 'large'
	handleError?: TError
	handleBlur?: TVoid
	errorMessage?: string
	showError?: boolean
}

export const InputNumber = React.forwardRef(
	(
		{
			label,
			value,
			prependText,
			placeholder,
			step,
			min,
			max,
			text,
			size,
			handleChange,
			handleError,
			handleBlur,
			required,
			errorMessage,
			showError
		}: IInputNumber,
		ref: React.ForwardedRef<any>
	): React.JSX.Element => {
		const prepend: React.MutableRefObject<HTMLElement | null> = useRef(null)
		const input: React.MutableRefObject<HTMLInputElement | null> = useRef(null)

		const [error, setError] = useState(false)
		const [localShowError, setLocalShowError] = useState(showError === true)

		useImperativeHandle(ref, () => input.current)

		const handleLocalError = createHandleLocalError(input, setError, localShowError, setLocalShowError, handleError)
		const handleLocalChange = (value: string): void => {
			handleLocalError()
			handleChange(value)
		}

		const handleParseValue = (value: string): number => {
			if (step && step !== 'any' && step % 1) {
				return toNumber(value)
			}

			return parseInt(value)
		}

		useEffect(createHandlePrepend(prepend, input))
		useEffect(handleLocalError, [value, min, max, step])

		useEffect(() => {
			if (!showError) return

			setLocalShowError(error && showError)
		}, [showError])

		return (
			<div
				className={classNames('form-field', {
					'with-error': error && localShowError
				})}>
				<div
					className={classNames('input', {
						'with-prepend': prependText,
						large: size === 'large',
						right: text === 'right'
					})}>
					{prependText && (
						<span className="prepend" ref={prepend}>
							{prependText}
						</span>
					)}
					<input
						type="number"
						placeholder={placeholder ?? label ?? ''}
						onChange={createHandleChange(handleLocalChange, handleParseValue)}
						onBlur={
							handleBlur
								? () => {
										handleBlur()
								  }
								: undefined
						}
						value={value || ''}
						step={step ?? 1}
						min={min ?? 1}
						max={max}
						ref={input}
						required={required}
					/>
				</div>
				{errorMessage && <div className="error">{errorMessage}</div>}
			</div>
		)
	}
)

InputNumber.displayName = 'InputNumber'
