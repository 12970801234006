import React from 'react'
import classNames from 'classnames'

interface INotification {
	title: string
	type: 'error'
}

export const Notification = ({ title, type }: INotification): React.JSX.Element => (
	<div
		className={classNames('notification-element', {
			error: type === 'error'
		})}>
		<p className="big">{title}</p>
	</div>
)
