import React from 'react'
import { Tag } from '../element'

export interface IHero {
	title: string
	headline: string
	tag?: {
		icon: React.JSX.Element
		label: string
	}
}

export const Hero = ({ title, headline, tag }: IHero): React.JSX.Element => (
	<section className="hero-section">
		<div className="headline">
			<h1>{title}</h1>
			<h4>{headline}</h4>
			{tag && <Tag icon={tag.icon} text={tag.label} />}
		</div>
	</section>
)
