import axios from 'axios'
import type React from 'react'
import { Donate } from '../utils/actionTypes'
import type { IDonateAction } from '../reducers'
import { apiUrl } from '../utils/defaults'

export interface IDonateFeeDetails {
	country: string
	is_fee_shouldered: boolean
	amount: number
}

export interface IDonateUserDetails {
	name: string
	email: string
	phone: string
}

export interface IDonatePaymentDetails {
	card_country: string
	is_fee_shouldered: boolean
	donation_amount: number
	recurring: boolean
	target_connect_account_id?: string
}

export const fetchDonation = (dispatch: React.Dispatch<IDonateAction>, id: string): void => {
	dispatch({
		type: Donate.FETCH.REQUESTED
	})

	axios
		.get(`${apiUrl}/v1/open/donation/widget/${id}`)
		.then((payload) => {
			const { data: response } = payload

			if (response.success) {
				dispatch({
					type: Donate.FETCH.SUCCESS,
					donation: response.data
				})
			} else {
				dispatch({
					type: Donate.FETCH.ERROR
				})
			}
		})
		.catch(() => {
			dispatch({
				type: Donate.FETCH.ERROR
			})
		})
}

export const calculateFeeDonation = (dispatch: React.Dispatch<IDonateAction>, feeDetails: IDonateFeeDetails): void => {
	dispatch({
		type: Donate.CALCULATE_FEE.REQUESTED
	})

	const data = {
		...feeDetails,
		currency: 'usd'
	}

	axios
		.post(`${apiUrl}/v1/open/payment/stripe/fees/calculate`, data)
		.then((payload) => {
			const { data: response } = payload

			if (response.success) {
				dispatch({
					type: Donate.CALCULATE_FEE.SUCCESS,
					fee: response.data
				})
			} else {
				dispatch({
					type: Donate.CALCULATE_FEE.ERROR
				})
			}
		})
		.catch(() => {
			dispatch({
				type: Donate.CALCULATE_FEE.ERROR
			})
		})
}

export const initDonation = (
	dispatch: React.Dispatch<IDonateAction>,
	id: string,
	uuid: string,
	paymentDetails: IDonatePaymentDetails,
	userDetails: IDonateUserDetails
): void => {
	dispatch({
		type: Donate.INIT.REQUESTED
	})

	const data = {
		donation: {
			widget_id: id,
			payment_details: {
				...paymentDetails,
				currency: 'usd'
			},
			user_details: userDetails,
			uuid
		}
	}

	axios
		.post(`${apiUrl}/v1/open/donation/init`, data)
		.then((payload) => {
			const { data: response } = payload

			if (response.success) {
				dispatch({
					type: Donate.INIT.SUCCESS,
					definition: response.data
				})
			} else {
				dispatch({
					type: Donate.INIT.ERROR
				})
			}
		})
		.catch(() => {
			dispatch({
				type: Donate.INIT.ERROR
			})
		})
}

export const confirmDonation = (dispatch: React.Dispatch<IDonateAction>, id: string): void => {
	dispatch({
		type: Donate.CONFIRM.REQUESTED
	})

	const data = {
		donation_id: id
	}

	axios
		.post(`${apiUrl}/v1/open/donation/confirm`, data)
		.then((payload) => {
			const { data: response } = payload

			if (response.success) {
				dispatch({
					type: Donate.CONFIRM.SUCCESS
				})
			} else {
				dispatch({
					type: Donate.CONFIRM.ERROR
				})
			}
		})
		.catch(() => {
			dispatch({
				type: Donate.CONFIRM.ERROR
			})
		})
}
