import React from 'react'
import { type IFormControl } from '../../interfaces'

export interface ICheckbox extends IFormControl<boolean> {
	placeholder?: string
}

export const Checkbox = ({ label, value, handleChange, required }: ICheckbox): React.JSX.Element => (
	<label className="checkbox">
		<input
			type="checkbox"
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				handleChange(e.target.checked)
			}}
			required={required}
			checked={value}
		/>
		{label}
	</label>
)
