import ReactPixel from 'react-facebook-pixel'
import { getRequestParameters } from './common'
import { type TRecord } from '../interfaces'

export interface IFbPixel {
	sendEvent: (name: string, data: TRecord<string | number>) => void
	sendView: () => void
}

export const getFbPixel = (): IFbPixel | null => {
	const { mpId: id } = getRequestParameters()

	if (!id || typeof id !== 'string') return null

	ReactPixel.init(id)

	return {
		sendEvent: (name: string, data: TRecord<string | number>): void => {
			ReactPixel.track(name, { ...data })
		},
		sendView: (): void => {
			ReactPixel.pageView()
		}
	}
}
