import React, { useCallback, useEffect, useRef, useState } from 'react'
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import axios from 'axios'

import { apiUrl } from '../../utils/defaults'
import { createHandleChange } from '../../utils/common'
import type { IFormControl, TVoid, TError } from '../../interfaces'
import classNames from 'classnames'
import { createHandlePrepend } from './utils/common'

export interface IInputPhone extends IFormControl<string | number> {
	type?: 'text' | 'email' | 'tel'
	prependText?: string
	placeholder?: string
	handleError?: TError
	handleBlur?: TVoid
	errorMessage?: string
	showError?: boolean
}

interface IPhonePrefix {
	name: string
	value: string
	code: string
	label: string
	img: string
}

export const InputPhone = ({
	label,
	value,
	placeholder,
	handleChange,
	handleError,
	handleBlur,
	required,
	errorMessage,
	showError
}: IInputPhone): React.JSX.Element => {
	const prepend: React.MutableRefObject<HTMLElement | null> = useRef(null)
	const input: React.MutableRefObject<HTMLInputElement | null> = useRef(null)

	const [prefixOptions, setPrefixOptions] = useState([])
	const [error, setError] = useState(false)
	const [localShowError, setLocalShowError] = useState(showError === true)
	const [showDropdown, setShowDropdown] = useState(false)
	const [countryCode, setCountryCode] = useState('US')
	const [phonePrefix, setPrefix] = useState('1')
	const [phoneNumber, setPhoneNumber] = useState('')

	const handleLocalError = useCallback(() => {
		const isError = !isValidPhoneNumber(value as string)
		setError(isError)

		if (handleError) {
			handleError(isError)
		}

		if (!isError && localShowError) {
			setLocalShowError(false)
		}
	}, [value, isValidPhoneNumber, setError, handleError, setLocalShowError, localShowError])

	const handleLocalChangeForPhoneNumber = useCallback(
		(prefix: string): void => {
			handleLocalError()
			setPhoneNumber(prefix)
			handleChange(`+${phonePrefix}${prefix}`)
		},
		[handleLocalError, setPhoneNumber, handleChange]
	)

	const handleLocalChangeForPrefix = useCallback(
		(value: string, countryCode: string): void => {
			handleLocalError()
			setPrefix(value)
			setCountryCode(countryCode)
			setShowDropdown(false)
			handleChange(`+${value}${phoneNumber}`)
		},
		[handleLocalError, setPrefix, handleChange, phoneNumber]
	)

	const handleInitialValue = useCallback((): void => {
		if (value) {
			const parsedNumber = parsePhoneNumber(value as string)

			if (parsedNumber) {
				const { countryCallingCode, nationalNumber } = parsedNumber
				setPhoneNumber(nationalNumber)
				setPrefix(countryCallingCode)
			}
		}
	}, [setPhoneNumber, setPrefix])

	useEffect(createHandlePrepend(prepend, input))
	useEffect(handleLocalError, [value])
	useEffect(handleInitialValue, [value])

	useEffect(() => {
		const fetchPhonePrefixes = async (): Promise<void> => {
			try {
				const resp = await axios.get(`${apiUrl}/v1/open/resources/phone-prefixes`)

				setPrefixOptions(resp?.data?.data?.prefixes || [])
			} catch (err) {
				// do nothing
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchPhonePrefixes()
	}, [])

	useEffect(() => {
		const fetchGeoLocation = async (): Promise<void> => {
			try {
				const resp = await axios.get(`${apiUrl}/v1/open/utils/geo-location`)

				const countryCode = resp?.data?.data?.country || 'US'

				setCountryCode(countryCode)

				if (prefixOptions.length) {
					const prefix: IPhonePrefix | undefined = prefixOptions.find(({ value: countryCodeValue }) => countryCodeValue === countryCode)

					if (prefix) {
						setPrefix((prefix as IPhonePrefix).code)
					}
				}
			} catch (err) {
				// do nothing
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchGeoLocation()
	}, [prefixOptions])

	useEffect(() => {
		if (!showError) return

		setLocalShowError(showError)
	}, [showError])

	return (
		<div
			className={classNames('form-field phone', {
				'with-error': error && localShowError
			})}>
			<div className={classNames('input with-select-prepend')}>
				<div className="select-prepend">
					<div className={`custom-select${showDropdown ? ' open' : ''}`}>
						<div className="custom-select-control" onClick={() => setShowDropdown(!showDropdown)}>
							<div className="select-selected">
								<span className={`flag flag-${countryCode.toLowerCase()}`}></span>
							</div>
							<div className="icon-wrapper">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7 10L12 15L17 10H7Z" fill="black" />
								</svg>
							</div>
						</div>
						<div className="select-items">
							{prefixOptions.map(({ value: countryCodeValue, code, label }: { code: string; value: string; label: string }) => (
								<button
									type="button"
									key={countryCodeValue}
									value={code}
									className={`select-item${code === phonePrefix && countryCodeValue === countryCode ? ' selected' : ''}`}
									onClick={() => handleLocalChangeForPrefix(code, countryCodeValue)}>
									<span className={`flag flag-${countryCodeValue.toLowerCase()}`}></span>
									{label}
								</button>
							))}
						</div>
					</div>
					{phonePrefix ? <span className="prefix">+{phonePrefix}</span> : null}
				</div>
				<input
					type="tel"
					placeholder={placeholder ?? label ?? ''}
					onChange={createHandleChange(handleLocalChangeForPhoneNumber)}
					onBlur={
						handleBlur
							? () => {
									handleBlur()
							  }
							: undefined
					}
					value={phoneNumber}
					ref={input}
					required={required}
				/>
			</div>
			{errorMessage && <div className="error">{errorMessage}</div>}
		</div>
	)
}
